<template>
  <v-menu
    v-model="menuState"
    content-class="datetime-picker-content"
    transition="scale-transition"
    offset-y
    min-width="290px"
    :disabled="disabled"
    :close-on-content-click="false"
    :close-on-click="true"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="displayedDateTime"
        v-on="!readonly ? on : null"
        color="tertiary"
        readonly
        :loading="loading"
        :disabled="disabled"
        :label="label"
        :clearable="clearable && !readonly"
        :error-messages="errorMessages"
      />
    </template>
    <v-date-picker
      v-if="showDatePicker"
      v-model="date"
      color="primary"
      scrollable
      class="datetime-picker-content"
      :locale="$store.state.auth.language"
      :allowed-dates="allowedDates"
      :max="maxDate"
      :min="minDate"
      @input="onDateInput"
    />
    <v-time-picker
      v-if="!showDatePicker"
      v-model="time"
      color="primary"
      class="datetime-picker-content"
      :locale="$store.state.auth.language"
      :format="'24hr'"
      :use-seconds="useSeconds"
      :max="maxTime"
      :min="minTime"
      @input="onTimeInput"
      @click:minute="!useSeconds && (menuState = false)"
      @click:second="menuState = false"
    />
  </v-menu>
</template>

<script>
  export default {
    name: "DateTimePicker",
    props: {
      value: String,
      displayFormat: {
        type: String,
        required: false,
        default() {
          return "D MMM YYYY, HH:mm";
        }
      },
      allowedDates: {
        type: Function,
        required: false,
        default: () => () => true
      },
      format: {
        type: String,
        required: false,
        default: "YYYY-MM-DDTHH:mm:ss"
      },
      label: {
        type: String,
        required: false,
        default() {
          return this.$t("global.date");
        }
      },
      errorMessages: {
        type: Array,
        required: false,
        default: () => []
      },
      useSeconds: {
        type: Boolean,
        required: false,
        default: false
      },
      loading: {
        type: Boolean,
        required: false,
        default: false
      },
      clearable: {
        type: Boolean,
        required: false,
        default: true
      },
      readonly: {
        type: Boolean,
        required: false,
        default: false
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      },
      max: {
        type: String,
        required: false
      },
      min: {
        type: String,
        required: false
      }
    },
    data: vm => ({
      menuState: false,
      date: null,
      time: "00:00:00",
      showDatePicker: true
    }),
    computed: {
      displayedDateTime: {
        get() {
          return this.dateTime.isValid() ? this.dateTime.format(this.displayFormat) : null;
        },
        set() {
          this.$emit("input", null);
          this.date = null;
          this.time = "00:00:00";
        }
      },
      dateTime() {
        return this.$moment.utc(this.value, this.format, this.$store.state.auth.language);
      },
      maxTime() {
        if (this.dateTime.isSame(this.$moment.utc(this.max), "day")) {
          return this.max ? this.$moment.utc(this.max).format("HH:mm:ss") : null;
        }
        return null;
      },
      minTime() {
        if (this.dateTime.isSame(this.$moment.utc(this.min), "day")) {
          return this.min ? this.$moment.utc(this.min).format("HH:mm:ss") : null;
        }
        return null;
      },
      maxDate() {
        return this.max ? this.$moment.utc(this.max).format("YYYY-MM-DD") : null;
      },
      minDate() {
        return this.min ? this.$moment.utc(this.min).format("YYYY-MM-DD") : null;
      }
    },
    watch: {
      value: {
        immediate: true,
        handler(value) {
          if (value) this.setDateAndTime();
        }
      },
      menuState(value) {
        if (value) {
          this.showDatePicker = true;
        }
      }
    },
    methods: {
      onDateInput(date) {
        this.$emit("input", this.$moment.utc(`${date} ${this.time}`).format(this.format) + "Z");
        this.showDatePicker = false;
      },
      onTimeInput(time) {
        this.$emit("input", this.$moment.utc(`${this.date} ${time}`).format(this.format) + "Z");
      },
      setDateAndTime() {
        if (this.dateTime.isValid()) {
          const minDateTime = this.$moment.utc(this.min);
          const maxDateTime = this.$moment.utc(this.max);
          this.date = this.dateTime.format("YYYY-MM-DD");
          this.time = this.dateTime.format("HH:mm:ss");
          if (this.min && this.dateTime.isBefore(minDateTime)) {
            this.time = minDateTime.format("HH:mm:ss");
            this.$emit("input", this.$moment.utc(`${this.date} ${this.time}`).format(this.format) + "Z");
          }
          if (this.max && this.dateTime.isAfter(maxDateTime)) {
            this.time = maxDateTime.format("HH:mm:ss");
            this.$emit("input", this.$moment.utc(`${this.date} ${this.time}`).format(this.format) + "Z");
          }
        }
      }
    }
  };
</script>
